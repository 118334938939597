//import logo from './logo.svg';
import zebra from "./free-animal-images.jpg"
import './App.css';
import { useState } from "react";
import { getBotResponse } from "./backend";


function App() {
  const [text, changeText] = useState("");
  const [displayText, changeDisplay] = useState("asdaf");

  const handleChange = (event) => {
      changeText(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let ans = await getBotResponse(text);
      console.log(ans);
      changeDisplay(ans);
      changeText("");
    } catch (e){
      console.log(e);
    }
  }


  return (
    <div className="App">
      <header className="App-header">
        <div>
        <img src={zebra} className="App-logo" alt="two zebras, it slowly spins" />
        <img src={zebra} className="App-logo" alt="two zebras, it slowly spins" />
        <img src={zebra} className="App-logo" alt="two zebras, it slowly spins" />
        </div>
        <p>
          Hi, I'm James. (not pictured)
        </p>

      

        <form onSubmit={handleSubmit}>
        <label>
          Ask a question about me to my bot:
        </label>
        <div></div>
        <input className = "input" type="text" value={text} onChange={handleChange} />
      </form>
      <p>
        {displayText}
      </p>

        <div><a className="small-text" href="https://github.com/limitedgit">github </a>
        &nbsp;
        <a className="small-text" href="https://ca.linkedin.com/in/james-wang-2a1573191">linkedin</a>
        &nbsp;
        <a className="small-text" href="mailto: jameswang0505@hotmail.com">contact</a>
        </div>
        
      

      </header>
    </div>
  );
}

export default App;
