import axios from "axios"

const backend = "https://nr6kc5e5x2.execute-api.us-east-1.amazonaws.com/default/mebot";



export const getBotResponse = async (mes) => {
    if (typeof(mes) !== "string"){
        return "bad message";
    }

    try {
        const result = await axios.post(backend, {
            message: mes,
        });
    
        return String(result.data);
    }
    catch( error){
        console.log("promise rejected: " + error);
        return "back end is busy or overwhelmed right now";
    }

}